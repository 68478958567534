import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: () => import("../views/PromiTech/index"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/PromiTech/Company/contact"),
  },

  {
    path: "/blog",
    name: "blog",
    component: () => import("../views/PromiTech/Company/blog.vue"),
  },
  ,
  {
    path: "/careers",
    name: "career",
    component: () => import("../views/PromiTech/Company/jobs2.vue"),
  },
  ,
  {
    path: "/digital-transformation",
    name: "contact",
    component: () =>
      import("../views/PromiTech/Services/digitaltransformation.vue"),
  },
  {
    path: "/data-center",
    name: "datacenter",
    component: () => import("../views/PromiTech/Services/datacenter.vue"),
  },
  {
    path: "/cloud",
    name: "cloud",
    component: () => import("../views/PromiTech/Services/cloud.vue"),
  },
  {
    path: "/security",
    name: "security",
    component: () => import("../views/PromiTech/Services/security.vue"),
  },
  {
    path: "/enterprise-network",
    name: "network",
    component: () => import("../views/PromiTech/Services/networking.vue"),
  },
  {
    path: "/company",
    name: "aboutus",
    component: () => import("../views/PromiTech/Company/aboutus.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});

export default router;
